body.page-home {
    .header-section {
        .content {
           background: white;

           padding: 60px;
           position: initial;
        }

        .shape-left {
            width: initial;
            min-height: initial;
            position: initial;
        }
    }

    .home-section-2 {
        width: 100vw;
        height: auto;
        bottom: 0;
        left: 0;
        background: url(../images/FamilyOnCouch_Optimized.jpg) left top no-repeat;
        margin: 0 auto;
        -webkit-background-size: cover;
        background-size: cover;
        position: relative;
        background-position-x: 30%;

        .content {
            background: white;

            position: initial;
            padding: 60px;
        }
    }

    .shape-left-2 {
        .content {
            background: white;

            padding: 60px;
            position: initial;

            height: initial;
        }
    }
}

body.page-about {
    #page #content #about .header {
        @media (min-width: 768px) {
            background-size: contain;
            min-height: 500px;
        }
    }

    #about-section-2-photo {
        background-position: 0px -20px;

        @media (min-width: 768px) {
            background-position: 0px -60px;
        }

        @media (min-width: 900px) {
            background-position: 0px -85px;
        }
    }

    .section-2-about .content {
        background: white;

        min-height: initial;

        @media (min-width: 1201px) {
            background: white;
            width: 45vw;
        }

        p {
            padding-top: initial;
            padding-left: initial;
        }
    }
}

body.page-custom-filters {
    #product .section-2 {
        @media (min-width: 1201px) {
            background-position: top center;
            background-size: cover;
        }
    }
    #product .section-2-about {
        background-image: url('../images/product-shape-left-long_Optimized.jpg');
    }
}

body.page-custom-filters-ga-01 {
    #page #content .header:before {
        display: none;
    }

    #product {
        .header {
            background: #fff;
            background-image: url('../images/custom-filters-ga-01-header-banner.jpg');
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;

            h5 {
                font-family: 'Didact Gothic';

                @media (min-width: 1024px) {
                    font-size: 3rem;
                }
            }

            p.small {
                font-family: 'Didact Gothic';
                font-size: 1.75rem;

                margin-bottom: 5px;

                text-transform: uppercase;
            }
        }

        .section-2 {
            background: $white;
        }
    }
}

body.page-crystal {
    #crystal {
        .header {
            background-attachment: initial;
        }

        .section-2 {
            background-image: url('../images/mom-baby-bath-web.jpg');
        }
    }

    #crystal .section-2-image {
        background-image: url('../images/final-palmtree-web.jpg');
    }

    .section-2 {
        .bullets {
            padding-right: 0;

            @media (min-width: 1024px) {
                padding-right: 200px;
            }
        }
    }
}

body.page-pure-pro-referrals {
    #page #content .header:before {
        display: none;
    }

    #pro {
        .header .header-content p {
            float: left;
            color: #fff;
            line-height: 1.2;
        }

        .header .header-content {
            width: 100%;
            color: #fff;
            font-family: "josefin Sans";
            float: left;
            padding: 150px 60px 0 50px;
        }

        .header .header-content section img {
            width: 80%;
        }

        .header .header-content section {
            width: 50vw;
            margin-right: auto;
        }

        .header {
            background: #fff;
            background-image: url('../images/pro-header-banner.jpg');
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
        }

        .section-2 {
        }
    }
}

body.page-crystal-ga-01 {
    #page #content .header:before {
        display: none;
    }

    #crystal {
        .header {
            background: #fff;
            background-image: url('../images/crystal-clear-ga-01-header-banner.jpg');
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;

            h5 {
                font-family: 'Didact Gothic';

                @media (min-width: 1024px) {
                    font-size: 3rem;
                }
            }

            p.small {
                font-family: 'Didact Gothic';
                font-size: 1.75rem;

                margin-bottom: 5px;

                text-transform: uppercase;
            }
        }

        .section-2 {
            background: $picton-blue;

            .content {
                background: transparent;
            }

            .left-shape {
                background: transparent;
            }

            .bullets {
                padding-right: 30px;

                li {
                    color: $white;

                    font-size: 1rem;
                    font-style: normal;

                    strong {
                        display: block;

                        font-size: 1.5rem;
                    }
                }
            }
        }
    }
}

body.page-conditioner {
    #conditioner {
        .section-2 {
            .content {
                display: block;
            }
        }
        .section-2-about {
            background-position-x: -300px;
        }

        .section-3 {
            padding-bottom: 0;
        }
    }
}

body.page-pinhole {
    #page {
        #content {
            .header {
                &:before {
                    @media (min-width: 1025px) {
                        background-color: rgba(0, 0, 0, 0.25);
                        display: block;
                    }
                }
            }
        }
    }

    #pinhole .header {
        background-attachment: initial;
        background-position: center top;
        background-image: url(../images/ManWithBucket_Image.jpg);
        background-repeat: no-repeat;

        color: #fff;

        @media screen and (min-width: 768px) {
            height: 500px;
        }

        .header-content {
            color: inherit;
        }

        .header-content h6,
        .header-content p {
            color: inherit;
        }
    }

    #pinhole .section-2 {
        background-size: cover;
        background-image: url('../images/pinhole-leak-in-pipe.jpg');
        background-repeat: no-repeat;
        background-position: center right;

        .content {
            background: white;
            min-height: initial;
            position: initial;
            top: initial;
            left: initial;
            right: initial;
            bottom: initial;

            width: 45vw;

            padding: 60px;

            p {
                padding-right: initial;
            }
        }
    }

    #pinhole .section-2-text .content {
        background: white;

        width: 55vw;

        padding: 60px;

        p {
            padding-top: initial;
            padding-left: initial;
        }
    }
}

body.page-pinhole-ga-01 {
    #maintenance {
        .video-container {
            padding-left: 60px;
            padding-right: 60px;
            padding-bottom: 60px;

            @media (min-width: 768px) {
                width: 50%;

                margin-left: 25%;
            }
        }
    }

    #page #content .header:before {
        display: none;
    }

    #pinhole {
        .header {
            background-image: url('../images/pinhole-ga-01-header-banner.jpg');
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;

            h5 {
                font-family: 'Didact Gothic';

                @media (min-width: 1024px) {
                    font-size: 3rem;
                }
            }

            p.small {
                font-family: 'Didact Gothic';
                font-size: 1.75rem;

                margin-bottom: 5px;

                text-transform: uppercase;
            }
        }

        .section-2 .content p {
            font-size: 0.85rem;
        }
    }
}

body.page-testimonials,
body.page-healthy {
    #page #content .header {
        background-size: cover;

        @media (min-width: 1025px) {
            background-size: contain;
        }
    }
}
