.cssclippathpolygon {
    body.page-home {
        .header-section {
            .content {
                width: 54vw;

                @media (min-width: 1024px) {
                    -webkit-clip-path: polygon(0 0,80% 0,113% 170%,0 100%);
                    clip-path: polygon(0 0,80% 0,113% 170%,0 100%);

                    padding: 30px 180px 60px 60px;
                }
            }
        }

        .home-section-2 {
            width: 100vw;
            height: auto;
            bottom: 0;
            left: 0;
            background: url(../images/FamilyOnCouch_Optimized.jpg) left top no-repeat;
            margin: 0 auto;
            -webkit-background-size: cover;
            background-size: cover;
            position: relative;
            background-position-x: 30%;

            .content {
                background: white;

                position: initial;

                width: 52vw;

                @media (min-width: 1024px) {
                    -webkit-clip-path: polygon(20% 100%,0% 120%,20% -100%, 110% 110%);
                    clip-path: polygon(20% 100%,0% 120%,20% -100%, 110% 110%);
                    background: white;

                    padding: 30px 90px 60px 120px;
                }
            }
        }

        .shape-left-2 {
            .content {
                width: 61vw;

                @media (min-width: 1024px) {
                    -webkit-clip-path: polygon(0 0,80% 0,113% 170%,0 100%);
                    clip-path: polygon(0 0,80% 0,113% 170%,0 100%);

                    padding: 30px 180px 60px 60px;
                }
            }
        }
    } // body.page-home

    body.page-about {
        #about .section-2 {
            background: initial;

            .content {
                display: block;

                width: 100%;

                @media (min-width: 1201px) {
                    padding-right: 0;
                }

                p {
                    padding-right: initial;
                }
            }

            .left-shape {
                @media (min-width: 1201px) {
                    width: 48%;
                    z-index: 2;
                    padding-right: 0;
                }
            }

            .right-shape {
                @media (min-width: 1201px) {
                    -webkit-background-size: cover;
                    background-size: cover;
                    background-image: url('../images/about-page-rick.jpg');
                    background-repeat: no-repeat;
                    background-position: center right;
                    width: 60%;
                    float: right;
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    -webkit-clip-path: polygon(35% 0,100% 0,100% 100%,14% 100%);
                    clip-path: polygon(35% 0,100% 0,100% 100%,14% 100%);
                }
            }
        }

        #about .section-2-text {
            background: initial;

            &:after {
                content: '';
                clear: both;
                display: block;
            }

            .content {
                width: 100%;

                min-height: initial;

                @media (min-width: 1201px) {
                }
            }

            .left-shape {
                @media (min-width: 1201px) {
                    background-size: cover;
                    background-image: url('../images/copper-pipe-image-2.jpg');
                    background-repeat: no-repeat;
                    background-position: center;

                    float: left;

                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;

                    width: 69%;

                    -webkit-clip-path: polygon(0% 0%,71% 0%,80% 100%,0% 100%);
                    clip-path: polygon(0% 0%,71% 0%,80% 100%,0% 100%);
                }
            }

            .right-shape {
                @media (min-width: 1201px) {
                    float: right;
                    width: 43%;
                }

                .content {
                    @media (min-width: 1201px) {
                        padding-left: 0;

                        min-height: initial;
                    }

                    p {
                        @media (min-width: 760px) {
                            padding: 0;
                        }
                    }
                }
            }
        }
    } // body.page-about

    body.page-pinhole {
        #pinhole .section-2 {
            background: initial;

            .content {
                width: 100%;

                @media (min-width: 1201px) {
                    padding-right: 0;
                }
            }

            .left-shape {
                @media (min-width: 1201px) {
                    width: 49%;
                    z-index: 2;
                    padding-right: 0;
                }
            }

            .right-shape {
                @media (min-width: 1201px) {
                    -webkit-background-size: cover;
                    background-size: cover;
                    background-image: url('../images/pinhole-leak-in-pipe.jpg');
                    background-repeat: no-repeat;
                    background-position: center right;
                    width: 60%;
                    float: right;
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    -webkit-clip-path: polygon(35% 0, 100% 0, 100% 100%, 15% 100%);
                    clip-path: polygon(35% 0, 100% 0, 100% 100%, 15% 100%);
                }
            }
        }

        #pinhole .section-2-text {
            background: initial;

            &:after {
                content: '';
                clear: both;
                display: block;
            }

            .content {
                width: 100%;

                @media (min-width: 1201px) {
                }
            }

            .left-shape {
                @media (min-width: 1201px) {
                    background-size: cover;
                    background-image: url('../images/house-2.jpg');
                    background-repeat: no-repeat;
                    background-position: bottom center;

                    float: left;

                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;

                    width: 69%;

                    -webkit-clip-path: polygon(0% 0%,71% 0%,80% 100%,0% 100%);
                    clip-path: polygon(0% 0%,71% 0%,80% 100%,0% 100%);
                }
            }

            .right-shape {
                @media (min-width: 1201px) {
                    float: right;
                    width: 43%;
                }

                .content {
                    @media (min-width: 1201px) {
                        padding-left: 0;

                        min-height: initial;
                    }
                }
            }
        }
    } // body.page-pinhole

    body.page-pinhole-ga-01 {
        #pinhole .section-2 {
            background: initial;

            .content {
                width: 100%;

                @media (min-width: 1201px) {
                    padding-right: 0;
                }
            }

            .left-shape {
                @media (min-width: 1201px) {
                    width: 50%;
                    z-index: 2;
                    padding-right: 0;
                }
            }

            .right-shape {
                @media (min-width: 1201px) {
                    -webkit-background-size: cover;
                    background-size: cover;
                    background-image: url('../images/pinhole-ga-01-sec1-right.jpg');
                    background-repeat: no-repeat;
                    background-position: center right;
                    width: 50%;
                    float: right;
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    -webkit-clip-path: polygon(0% 0,100% 0,100% 100%,18% 100%);
                    clip-path: polygon(0% 0,100% 0,100% 100%,18% 100%);
                }
            }
        }
    } // body.page-pinhole-ga-01

    body.page-crystal-ga-01 {
        #crystal .section-2 {
            .content {
                width: 100%;

                @media (min-width: 1201px) {
                    padding-right: 0;
                }
            }

            .left-shape {
                padding-top: 85px;

                @media (min-width: 1201px) {
                    width: 49%;
                    z-index: 2;
                    padding-right: 0;
                    padding-top: 0;
                }
            }

            .right-shape {
                @media (min-width: 1201px) {
                    -webkit-background-size: cover;
                    background-size: cover;
                    background-image: url('../images/crystal-clear-ga-01-sec1-right.jpg');
                    background-repeat: no-repeat;
                    background-position: center right;
                    width: 60%;
                    float: right;
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    -webkit-clip-path: polygon(35% 0,100% 0,100% 100%,15% 100%);
                    clip-path: polygon(35% 0,100% 0,100% 100%,15% 100%);
                }
            }

            .tag {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                text-align: center;
                background-color: white;
                padding: 15px;

                @media (min-width: 1201px) {
                    left: 35%;

                    transform: translate3d(0px,-87px,0px);

                    -webkit-clip-path: polygon(5% 0%,100% 0%,100% 100%,0% 100%);
                    clip-path: polygon(5% 0%,100% 0%,100% 100%,0% 100%);
                }
            }
        }
    } // body.page-crystal-ga-01

    body.page-custom-filters-ga-01 {
        #product .section-2 {
            .content {
                width: 100%;

                @media (min-width: 1201px) {
                    padding-right: 0;
                }
            }

            .left-shape {
                @media (min-width: 1201px) {
                    padding-top: 60px;
                    padding-bottom: 60px;
                    padding-right: 0;

                    width: 49%;
                    z-index: 2;
                }

                p {
                    @media (min-width: 1201px) {
                        padding-right: 60px;
                    }
                }
            }

            .right-shape {
                @media (min-width: 1201px) {
                    -webkit-background-size: cover;
                    background-size: cover;
                    background-image: url('../images/custom-filters-ga-01-sec1-right.jpg');
                    background-repeat: no-repeat;
                    background-position: center right;
                    width: 60%;
                    float: right;
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    -webkit-clip-path: polygon(35% 0,100% 0,100% 100%,15% 100%);
                    clip-path: polygon(35% 0,100% 0,100% 100%,15% 100%);
                }
            }
        }
    } // body.page-custom-filters-ga-01
}
