.mdl-selectfield--floating-label.is-dirty .mdl-selectfield__label {
    color: rgb(33,150,243);
    font-size: 12px;
    top: 4px;
    visibility: visible;
}

.mdl-radio--floating-label {
    position: relative;
    top: 0;
    bottom: 0;
    color: rgba(0,0,0,.26);
    font-size: 16px;
    left: 0;
    right: 0;
    pointer-events: none;
    display: block;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-align: left;
}

.mdl-radio--floating-label.is-dirty .mdl-radio {
    color: rgb(33,150,243);
    font-size: 12px;
    top: 4px;
    visibility: visible;
}

.mdl-radio {
    font-size: 12px;
    margin-right: 15px;
}
