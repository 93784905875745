.button_container {
    @media (max-width: 670px) {
        right: 0px;
    }
}

#page > .nav {
    .phone {
        right: 265px;

        @media (max-width: 670px) {
            left: 30px;
        }
    }

    .mail {
        right: 180px;

        @media (max-width: 670px) {
            left: -45px;
        }

        a {
            display: block;
            width: 100%;
            height: 100%;
        }
    }

    .pro {
        top: 13px;
        right: 100px;
        width: 54px;
        height: 54px;
        position: absolute;
        margin: 0 auto;
        cursor: pointer;
        background: url(../images/pro-icon.png) no-repeat center center;
        background-size: contain;

        @media (max-width: 670px) {
            top: 14px;
            right: 95px;
        }

        a {
            display: block;
            width: 100%;
            height: 100%;
        }
    }
}
