#page {
    #content {
        .header {
            background-size: cover;
            position: relative;

            &:before {
                content: "";

                background-color: rgba(0, 0, 0, 0.5);

                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;

                width: 100%;
                height: 100%;

                @media (min-width: 1025px) {
                    display: none;
                }
            }

            .header-content {
                padding-top: 100px;
                padding-right: 50px;
                padding-bottom: 20px;
                padding-left: 50px;

                @media (min-width: 671px) {
                    padding-top: 50px;
                    padding-bottom: 0;
                }

                .small {
                    font-size: 2vw;
                }
            }
        }
    }
}
