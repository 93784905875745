html {
    height: 100%;
    font-size: 100%;
}

body {
    background-color: $body-background-color;

    font-family: $sans-serif-font;
    font-size: 16px;
    word-spacing: 1px;
}

section {
    position: relative;
}

a {
    color: $brand-primary-color;

    -webkit-transition: color 0.2s;
    transition: color 0.2s;
}

a:focus, a:hover {
    color: lighten($brand-primary-color, 20%);
}

li,
p {
    line-height: 1.45;
    margin-bottom: 1rem;

    &:last-child {
        margin-bottom: 0;
    }
}

.vertical-center-wrap {
    width: 100%;
    min-height: 100%;
}

.vertical-center-wrap {
    height: 100%;
    overflow: hidden;
}

/* content centering styles */
.vertical-center-wrap {
    display: table;
    height: 100%;
}

.vertical-center-content {
    display: table-cell;
    position: relative;
    vertical-align: middle;
}

.bullets,
.testimonials {
    list-style-type: none;

    li {
        font-family: 'Lato', sans-serif;
        font-size: inherit;
        font-weight: 300;
        font-style: italic;

        &:nth-child(odd) {
            clear: both;
        }

        strong {
            font-style: normal;
        }
    }
}

.testimonials {
    li {
        blockquote {
            border: none;

            font: inherit;
            font-style: italic;

            letter-spacing: inherit;

            quotes: "\201C""\201D""\2018""\2019";

            padding: 0;
            margin: 0;

            &:before {
                content: open-quote;
                position: initial;
                left: initial;
            }

            &:after {
                content: close-quote;
                position: initial;
                left: initial;
            }
        }

        .author {
            font-size: smaller;
            font-weight: bold;

            margin-top: 10px;

            &:before {
                content: "\2014";

                margin-right: 5px;
            }
        }
    }
}

ul.big-margins {
    li {
        margin-bottom: 4rem;
    }
}

ul.big-text {
    font-size: 1.5rem;
}

ul.inset {
    padding-left: 12px;
}

ul.two-col-with-gutter {
    li {
        &:nth-child(odd) {
            @media (min-width: 992px) {
                padding-right: 4rem;
            }
        }

        &:nth-child(even) {
            @media (min-width: 992px) {
                padding-left: 4rem;
            }
        }
    }
}

.list-heading {
    margin-top: 30px;
    margin-bottom: 20px;
}

.overlay {
    ul {
        li {
            margin-bottom: 0;
        }
    }
}

.content p,
.content-section p {
    line-height: 2;
}

.content-section {
    padding: 3rem 0;

    p {
      font-size: 1em;
      font-family: "Muli";
      font-weight: 300;
    }
}

.content-section.more-padding-up-top {
    padding-top: 6rem;
}

.section-2 {
    .content {
        padding-top: 30px;

        p {
            padding-top: 0;
        }
    }
}

.section-2,
.section-2-about {
    p.small {
        font-size: 0.65rem;

        line-height: 1.25;
    }
}

section.wall-to-wall-image {
    border-top: $section-divider-border;

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    width: 100vw;
    height: 50vh;

    margin: 0 auto;
    position: relative;

    bottom: 0;
    left: 0;
}

.bottom-photos h6 {
    @media (min-width: 620px) {
        font-size: 1rem;
    }

    @media (min-width: $screen-sm-min) {
        font-size: 0.5rem;
    }

    @media (min-width: $screen-md-min) {
        font-size: 0.75rem;
    }
}

.message-area {
    &.success,
    &.error {
        padding-top: 1rem;
    }

    &.error {
        color: red;
    }
}

.embed-responsive {
    position: relative;
    display: block;
    height: 0;
    padding: 0;
    overflow: hidden;
}

.embed-responsive-16by9 {
    padding-bottom: 56.25%;
}

.embed-responsive .embed-responsive-item,
.embed-responsive embed,
.embed-responsive iframe,
.embed-responsive object,
.embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}
